import styled from 'styled-components'

export const DrawerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Title = styled('div')`
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`

export const CheckBoxWrapper = styled(DrawerWrapper)`
  margin-bottom: 46px;
`

export const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  gap: 8px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 16px;
  z-index: 2;
`

import { createDomain, sample } from 'effector'
import { AxiosErrorType, Inspection } from '~/shared/api'
import { replaceFx } from '~/shared/lib/history'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

const domain = createDomain('entities.SafetyInspection')

export const openSafetyInspectionModal = domain.createEvent<{
  inspectionId: UniqueId
}>()
export const closeSafetyInspectionModal = domain.createEvent()

export const $inspectionId = domain
  .createStore<UniqueId | null>(null)
  .on(openSafetyInspectionModal, (_, { inspectionId }) => inspectionId)
  .on(closeSafetyInspectionModal, () => null)

export const sentToSecurity = domain.createEvent()
export const sentToSecurityFx = domain.createEffect<
  UniqueId,
  void,
  AxiosErrorType
>({
  handler: async (inspectionId) => {
    await Inspection.sentToSecurity(inspectionId)
  },
})

sample({
  clock: sentToSecurity,
  source: $inspectionId,
  filter: isString,
  target: sentToSecurityFx,
})

sample({
  clock: sentToSecurityFx.doneData,
  fn() {
    return {
      message: 'Данные СБ отправлены',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: sentToSecurityFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

export const goToInspectionsFx = domain.createEffect({
  handler() {
    replaceFx('/')
  },
})

sample({
  clock: sentToSecurityFx.finally,
  target: [closeSafetyInspectionModal, goToInspectionsFx],
})

import { IonChip, IonIcon, IonLabel } from '@ionic/react'
import { useStore } from 'effector-react'
import { bulb } from 'ionicons/icons'
import { useForm } from 'react-hook-form'
import { Checkbox, FormProvider } from '~/shared/ui/form'
import { Modal } from '~/shared/ui/Modal'
import {
  $inspectionId,
  sentToSecurity,
  sentToSecurityFx,
  closeSafetyInspectionModal,
  goToInspectionsFx,
} from './model'
import { Title, Description } from './styled'

export function SafetyInspection() {
  const inspectionId = useStore($inspectionId)
  const isSentToSecurityPending = useStore(sentToSecurityFx.pending)

  const form = useForm<{ isDriverWasSentToSecurity: boolean }>()

  const handleSentToSecurity = () => {
    const isDriverWasSentToSecurity = form.getValues(
      'isDriverWasSentToSecurity',
    )

    if (isDriverWasSentToSecurity) {
      sentToSecurity()
    } else {
      closeSafetyInspectionModal()
      goToInspectionsFx()
    }
  }

  return (
    <Modal
      isShow={Boolean(inspectionId)}
      onOk={handleSentToSecurity}
      ok='Подтвердить'
      canBackdropClose={false}
      isLoading={isSentToSecurityPending}
    >
      <Title>Служба безопасности</Title>

      <IonChip color='danger' style={{ marginBottom: '16px' }}>
        <IonIcon icon={bulb}></IonIcon>
        <IonLabel>
          Внимание! Водителя необходимо направить в отдел службы безопасности
          для дальнейшего разбирательства.
        </IonLabel>
      </IonChip>

      <Description>
        Пожалуйста, убедитесь, что водитель проинформирован и следует
        установленным инструкциям.
      </Description>

      <FormProvider form={form}>
        <Checkbox
          label='Водитель отправлен в СБ'
          name='isDriverWasSentToSecurity'
          style={{ margin: '12px 0' }}
        />
      </FormProvider>
    </Modal>
  )
}

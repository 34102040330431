import {
  IonActionSheet as IonicIonActionSheet,
  IonIcon as IonicIonIcon,
} from '@ionic/react'
import { styled } from 'styled-components'
import { BODY_MAX_WIDTH, myPalette } from '~/shared/ui/theme'

export const IonIcon = styled(IonicIonIcon)<{
  $reverse?: boolean
}>`
  position: absolute;
  right: 12px;
  bottom: 12px;
  z-index: 1;

  ${({ $reverse }) => {
    if ($reverse) {
      return {
        transform: 'rotate(180deg)',
      }
    }
  }}
`

export const TextFieldElementWrapper = styled.div<{
  $disabled?: boolean
}>`
  position: relative;
  width: 100%;
  height: 57px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  cursor: pointer;

  ${({ $disabled }) => {
    if ($disabled) {
      return {
        width: 'pointer-event: none',
      }
    }
  }}
`

export const TextFieldLabel = styled.div<{
  $isTop?: boolean
}>`
  position: absolute;
  transition: 0.03s;
  left: 0;
  width: 100%;

  font-size: ${({ $isTop }) => ($isTop ? '12px' : '16px')};
  top: ${({ $isTop }) => ($isTop ? '0px' : '22px')};
  color: ${myPalette.dimGray.main};
`

export const TextFieldValue = styled.div`
  padding-top: 22px;
  overflow: auto;
  text-wrap: nowrap;
  scrollbar-width: none;
  margin-right: 40px;

  &&::-webkit-scrollbar {
    display: none;
  }
`

export const ErrorMessage = styled.div`
  font-size: 12px;
  margin: 4px 0 0;
  color: ${myPalette.red.main};
`

export const IonActionSheet = styled(IonicIonActionSheet)`
  --max-width: ${BODY_MAX_WIDTH};
  --background: ${myPalette.aliceBlue.main};

  .action-sheet-group {
    border-radius: 4px 4px 0 0;
    padding: 0 16px 24px !important;
  }

  .action-sheet-title {
    font-size: 24px;
    padding-left: 0;
    font-weight: 500;
    color: ${myPalette.black.main};
  }

  .action-sheet-button {
    padding: 8px 16px;
    font-size: 18px;
    min-height: 44px;
    margin: 0;
    border-radius: 4px;

    &:hover {
      margin: 0;
    }

    &.action-sheet-selected {
      font-weight: 500;
      background: rgba(33, 150, 243, 0.08);
    }
  }
`

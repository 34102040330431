import { useQueryParam } from 'use-query-params'
import { ListInspections } from 'src/features/ListInspections'
import { ListSearchCar } from 'src/features/ListSearchCar'
import { usePermissions } from '~/entities/Viewer'
import { constants } from '~/shared/config'
import { pushFx } from '~/shared/lib/history'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { PageContainer } from '~/shared/ui/PageContainer'

function HomePage() {
  const { canInspectionCreate } = usePermissions()
  const [searchFilter] = useQueryParam<string>(constants.HEADER_SEARCH_KEY)

  const goToInspection = (carId: UniqueId) => {
    if (!canInspectionCreate) {
      snackbarEnqueued({
        message: 'У вас нет доступа для проведения осмотра',
        variant: 'warning',
      })
      return
    }
    pushFx(`/inspection/${carId}`)
  }

  return (
    <PageContainer
      canSearch
      helmetTitle='Механик'
      contentTitle={searchFilter ? '' : 'Осмотрено за сегодня'}
      canLogout
    >
      {searchFilter ? (
        <ListSearchCar onInspectionStart={goToInspection} />
      ) : (
        <ListInspections />
      )}
    </PageContainer>
  )
}

export default HomePage

import { UseFormReturn } from 'react-hook-form'
import {
  documentsCodeEnumOptions,
  reasonsTerminationsOptions,
} from '~/shared/config/constants'
import { Button } from '~/shared/ui/Buttons'
import { Checkbox, DrawerSelectMultiple, FormProvider } from '~/shared/ui/form'
import { TextareaInput } from '~/shared/ui/form/TextareaInput'
import { FormHeader } from '../FormHeader'
import { CheckBoxListWrapper, Wrapper } from './styled'
import { DocumentsFormValues } from './types'

type DocumentsFormProps = {
  onSuccess: () => void
  form: UseFormReturn<DocumentsFormValues>
  submitTitle: string
  isSubmitting: boolean
  isRefund?: boolean
}

export function DocumentsForm({
  onSuccess,
  form,
  submitTitle,
  isSubmitting,
  isRefund,
}: DocumentsFormProps) {
  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Wrapper>
        <CheckBoxListWrapper>
          {documentsCodeEnumOptions.map((param) => (
            <Checkbox
              key={param.id}
              label={param.label}
              name={`documents.${param.id}`}
            />
          ))}
        </CheckBoxListWrapper>

        <FormHeader title='Комментарий' />

        <TextareaInput
          name='comment'
          rows={6}
          style={{ marginBottom: '12px' }}
        />

        {isRefund && (
          <>
            <FormHeader title='Расторжения договора' />
            <DrawerSelectMultiple
              label='Причины расторжения'
              name='refundReasons'
              title='Причины расторжения'
              options={reasonsTerminationsOptions}
              validation={{
                required: 'Обязательное поле',
              }}
            />

            <Checkbox
              label='Автомобиль готов к аренде'
              name='isCarReadyForRent'
              style={{ margin: '12px 0' }}
            />
          </>
        )}

        <Button
          fullWidth
          type='submit'
          variant='limeFlooded'
          uppercase
          style={{ marginTop: '16px' }}
          loading={isSubmitting}
        >
          {submitTitle}
        </Button>
      </Wrapper>
    </FormProvider>
  )
}

import { z } from 'zod'
import { Inspection } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { DocumentsTypeEnum } from '~/shared/config/enums'

export const documentsFormSchema = Inspection.schema.pick({
  comment: true,
  refundReasons: true,
  isCarReadyForRent: true,
})

export type TypeFormSchema = z.infer<typeof documentsFormSchema>

export type DocumentsFormValues = Omit<TypeFormSchema, 'refundReasons'> & {
  documents: Record<DocumentsTypeEnum, boolean>
  refundReasons?: Option[]
}

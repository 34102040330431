import { IonLoading } from '@ionic/react'
import { useGate, useStore } from 'effector-react'
import { useEffect, useRef } from 'react'
import { Prompt, useParams } from 'react-router-dom'
import { useCameraContext } from '~/widgets/CameraProvider/context'
import { Inspection, inspectionModel } from '~/features/Inspection'
import { inspectionStepModel } from '~/features/Inspection'
import {
  checkDriverFx,
  inspectionCreateFx,
  saveDamagesMapScreenFx,
} from '~/features/Inspection/model'
import {
  inspectionFormModel,
  RejectInspectionModal,
} from '~/entities/Inspection'
import { safetyInspectionModalModal } from '~/entities/SafetyInspection'
import { InspectionStepsEnum } from '~/shared/config/enums'
import { pushFx, replaceFx } from '~/shared/lib/history'
import { useModal } from '~/shared/ui/Modal'
import { PageContainer } from '~/shared/ui/PageContainer'
import { LoadingBlock } from './ui/LoadingBlock'
import { TimerButton } from './ui/TimerButton'

function InspectionPage() {
  const params = useParams<{ carId: string }>()
  const carId = params.carId as string
  const { isCameraOpen } = useCameraContext()

  const contentRef = useRef<HTMLIonContentElement>(null)

  useGate(inspectionStepModel.Gate)
  useGate(inspectionModel.Gate, { carId })

  const isCarLoading = useStore(inspectionModel.requestCarFx.pending)
  const car = useStore(inspectionModel.$car)

  const isInspectionCreatePending = useStore(inspectionCreateFx.pending)
  const isSaveDamagesMapScreenPending = useStore(saveDamagesMapScreenFx.pending)
  const isCheckDriverPending = useStore(checkDriverFx.pending)
  const isSubmitting =
    isInspectionCreatePending ||
    isSaveDamagesMapScreenPending ||
    isCheckDriverPending

  const isSentToSecurityPending = useStore(
    safetyInspectionModalModal.sentToSecurityFx.pending,
  )

  const isLastInspectionPending = useStore(
    inspectionFormModel.requestLastInspectionFx.pending,
  )

  const { isModalShow, openModal, closeModal } = useModal()

  const currentStep = useStore(inspectionStepModel.$currentStep)

  const isFirstStep = currentStep === InspectionStepsEnum.SPECIFICATION_STEP

  const backFn = isFirstStep ? openModal : inspectionStepModel.goToBackStep
  const historyBackFn = () => {
    backFn()
    // Возвращаем false для того что бы Prompt не изменил url
    return false
  }

  const handleRejectInspectionModalClose = () => {
    // Push так как после перезагрузки страницы Prompt не видит историю и подставляет предыдущий url
    pushFx(carId)
    closeModal()
  }

  const handlePopoverReject = () => {
    openModal()
  }

  const handleRejectInspectionModalOk = () => {
    replaceFx('/')
    closeModal()
  }
  const isLoading = !car || isCarLoading || isLastInspectionPending

  useEffect(() => {
    contentRef.current?.scrollToTop?.()
  }, [currentStep])

  return (
    <>
      <IonLoading
        isOpen={isInspectionCreatePending}
        message='Сохранение осмотра'
      />
      <PageContainer
        contentRef={contentRef}
        helmetTitle='Осмотр'
        canBack
        backFn={backFn}
        backTitle={isFirstStep ? 'Выйти' : 'Шаг назад'}
        extraBlock={
          !isLoading && <TimerButton onPopoverReject={handlePopoverReject} />
        }
      >
        <Prompt
          when={
            !isModalShow &&
            !isCameraOpen &&
            !isSubmitting &&
            !isSentToSecurityPending
          }
          message={historyBackFn}
        />
        {isLoading ? <LoadingBlock /> : <Inspection carId={carId} />}

        <RejectInspectionModal
          isShow={isModalShow}
          onClose={handleRejectInspectionModalClose}
          onOk={handleRejectInspectionModalOk}
        />
      </PageContainer>
    </>
  )
}

export default InspectionPage

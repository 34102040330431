import { caretDown } from 'ionicons/icons'
import { useCallback, useEffect, useState } from 'react'
import { Controller, ControllerProps, useFormContext } from 'react-hook-form'
import { Option } from '~/shared/config/constants'
import { Button } from '~/shared/ui/Buttons'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'
import {
  ErrorMessage,
  IonIcon,
  TextFieldElementWrapper,
  TextFieldLabel,
  TextFieldValue,
} from '~/shared/ui/form/DrawerSelect/styled'
import { Checkbox } from './Checkbox'
import { Title, DrawerWrapper, ButtonsWrapper, CheckBoxWrapper } from './styled'

type DrawerSelectMultipleProps<T extends Option> = {
  options: T[]
  name: string
  label: string
  title: string
  disabled?: boolean
  dataTestId?: string
  validation?: ControllerProps['rules']
}

export function DrawerSelectMultiple<T extends Option>({
  options,
  name,
  label,
  title,
  disabled,
  dataTestId,
  validation = {},
}: DrawerSelectMultipleProps<T>) {
  const { openDrawer, closeDrawer, isDrawerShow } = useDrawer()
  const { watch } = useFormContext()
  const valuesForm: T[] = watch(name) || []
  const [selectedOptions, setSelectedOptions] = useState<T[]>(valuesForm || [])

  const handleDriverOpen = () => {
    if (disabled) return
    openDrawer()
  }

  const handleSelectedValueCheck = useCallback(
    (option: T) =>
      selectedOptions.some((selected) => selected.id === option.id),
    [selectedOptions],
  )

  const handleOnChange = useCallback(
    (option: T) => {
      if (handleSelectedValueCheck(option)) {
        setSelectedOptions((prevOptions) =>
          prevOptions.filter((value) => value.id !== option.id),
        )
      } else {
        setSelectedOptions((prevOptions) => [...prevOptions, option])
      }
    },
    [handleSelectedValueCheck],
  )

  useEffect(() => {
    setSelectedOptions(valuesForm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerShow])

  return (
    <div data-testid={dataTestId}>
      <Controller
        name={name}
        rules={validation}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextFieldElementWrapper
              $disabled={disabled}
              onClick={handleDriverOpen}
            >
              <TextFieldLabel
                data-testid='drawer-select-label'
                $isTop={Boolean(value?.length)}
              >
                {label}
              </TextFieldLabel>
              <TextFieldValue data-testid='drawer-select-value'>
                {value?.map((v: T) => v.label).join(', ')}
              </TextFieldValue>

              {!disabled && (
                <IonIcon icon={caretDown} $reverse={isDrawerShow} />
              )}
            </TextFieldElementWrapper>

            {error && !value?.length && (
              <ErrorMessage data-testid='drawer-select-error'>
                {error.message}
              </ErrorMessage>
            )}

            <DrawerComponent
              isShow={isDrawerShow}
              onCancel={closeDrawer}
              contentProps={{
                className: 'ion-content-scroll-host',
              }}
            >
              <DrawerWrapper>
                <Title>{title}</Title>

                <>
                  <CheckBoxWrapper>
                    {options.map((option) => (
                      <Checkbox
                        key={option.id}
                        label={option.label}
                        onChange={() => handleOnChange(option)}
                        checked={handleSelectedValueCheck(option)}
                      />
                    ))}
                  </CheckBoxWrapper>

                  <ButtonsWrapper>
                    <Button
                      fullWidth
                      variant='primaryOutline'
                      onClick={() => {
                        setSelectedOptions([])
                        onChange(undefined)
                        closeDrawer()
                      }}
                    >
                      Сбросить
                    </Button>
                    <Button
                      fullWidth
                      variant='limeFlooded'
                      onClick={() => {
                        onChange(selectedOptions)
                        closeDrawer()
                      }}
                    >
                      Применить
                    </Button>
                  </ButtonsWrapper>
                </>
              </DrawerWrapper>
            </DrawerComponent>
          </>
        )}
      />
    </div>
  )
}

import { useQuery } from '@tanstack/react-query'
import { caretDown } from 'ionicons/icons'
import { useMemo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { Option } from '~/shared/config/constants'
import { useDrawer } from '~/shared/ui/Drawer'
import { ProgressOverlay } from '~/shared/ui/Loaders'
import {
  TextFieldElementWrapper,
  IonIcon,
  TextFieldLabel,
  TextFieldValue,
  IonActionSheet,
  ErrorMessage,
} from './styled'

type DrawerSelectProps = {
  defaultOptions?: Option[]
  name: string
  label: string
  title: string
  queryKey?: string
  fetchOptions?: () => Promise<Option[]>
  disabled?: boolean
  dataTestId?: string
  loading?: boolean
  validation?: ControllerProps['rules']
}

export function DrawerSelect({
  defaultOptions,
  name,
  label,
  title,
  queryKey,
  fetchOptions,
  disabled,
  dataTestId,
  loading,
  validation = {},
}: DrawerSelectProps) {
  const { openDrawer, closeDrawer, isDrawerShow } = useDrawer()

  const handleOptionsFetch = () => {
    return fetchOptions?.() || []
  }

  const { isFetching, data } = useQuery<Option[]>(
    ['drawer-select', name, queryKey],
    () => handleOptionsFetch(),
    {
      enabled: Boolean(fetchOptions),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )

  const options = useMemo(
    () => defaultOptions ?? data ?? [],
    [data, defaultOptions],
  )

  const handleDriverOpen = () => {
    if (disabled) return
    openDrawer()
  }

  return (
    <div data-testid={dataTestId}>
      <Controller
        name={name}
        rules={validation}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <ProgressOverlay
            loading={loading || (!defaultOptions && isFetching)}
            spinnerProps={{ style: { size: 18 } }}
          >
            <TextFieldElementWrapper
              $disabled={disabled}
              onClick={handleDriverOpen}
            >
              <TextFieldLabel
                data-testid='drawer-select-label'
                $isTop={Boolean(value?.label)}
              >
                {label}
              </TextFieldLabel>
              <TextFieldValue data-testid='drawer-select-value'>
                {value?.label}
              </TextFieldValue>

              {!disabled && (
                <IonIcon icon={caretDown} $reverse={isDrawerShow} />
              )}
            </TextFieldElementWrapper>

            {error && !value && (
              <ErrorMessage data-testid='drawer-select-error'>
                {error.message}
              </ErrorMessage>
            )}
            <IonActionSheet
              mode='md'
              isOpen={isDrawerShow}
              header={title}
              buttons={options.map((option) => ({
                text: option.label,
                role: option.id === value?.id ? 'selected' : 'destructive',
                data: option,
              }))}
              onIonActionSheetWillDismiss={(e) => {
                e.detail.data && onChange(e.detail.data)
                closeDrawer()
              }}
            />
          </ProgressOverlay>
        )}
      />
    </div>
  )
}
